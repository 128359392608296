import * as React from "react";
import { FormEvent, useEffect, useState } from "react";
import {
  GetSurveyResponses,
  ISurveySequence,
  SkipLogicType,
  SurveyRespond,
  TApiAnswerEntity,
  TApiQuestionEntity,
  TSurveysState,
} from "../../../types/Survey";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnswer,
  getAnswerComment,
  getCategory,
  getQuestion,
} from "../../../helpers/Survey";
import { IAppState } from "../../../store/Store";
import { compose } from "redux";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import { routes } from "../../../Routes";
import { reverse } from "named-urls";
import {
  getSurveyResponses,
  surveyRespond,
} from "../../../actions/SurveyActions";
import { TDispatch } from "../../../types/Thunk";
import AlertBlock from "../../../components/AlertBlock";
import { useApp } from "../../../hooks/useApp";
import Loader from "../../../components/Loader";
import { find, findIndex, isEmpty, throttle } from "lodash";
import { setCurrentPageTitle } from "../../../actions/ContentActions";
import AnswerCommentModal from "../../../components/modals/AnswerCommentModal";
import Question from "../../../components/survey/Question";
import {
  loadStoredRespondentState,
  useSkipLogic,
  useSurveyNavigation,
} from "../../../hooks/useCurrentActiveSurvey";
import { SurveyInspectionType } from "../../../types/Content";
import {
  setInspectionQuestion,
  setInspectionSent,
} from "../../../actions/InspectionActions";
import { useInspection } from "../../../hooks/useInspection";
import SurveyTranslation from '../../../components/survey/SurveyTranslation';

const SurveyQuestionPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const [selectedAnswer, setSelectedAnswer] = useState<any>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();
  const respondentIdentifier = loadStoredRespondentState();
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );
  const [question, setQuestion] = useState<TApiQuestionEntity | undefined>(
    undefined
  );
  const [nextQuestion, setNextQuestion] = useState<
    TApiQuestionEntity | undefined
  >(undefined);
  const [nextCategory, setNextCategory] = useState<ISurveySequence | undefined>(
    undefined
  );
  const { isInspectionStored, isInspectionSent } = useInspection();
  const [nextSubCategory, setNextSubCategory] = useState<
    ISurveySequence | undefined
  >(undefined);
  const [showCommentBlock, setShowCommentBlock] = useState(false);
  const [showSuccessReporting, setShowSuccessReporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const { app } = useApp();
  const { findSkipLogic, getQuestionParentInfo, getCategoryParentId } =
    useSkipLogic();

  const { goToQuestion, goToCategory, goToFinishSurveyPage } =
    useSurveyNavigation(props.history);

  const surveyId: number = parseInt(props.match.params.id);
  const currentCategoryId: number = parseInt(props.match.params.categoryId);
  const currentSubcategoryId: number | null = props.match.params.subCategoryId
    ? parseInt(props.match.params.subCategoryId)
    : null;
  const currentQuestionId: number | null = props.match.params.questionId
    ? parseInt(props.match.params.questionId)
    : null;

  useEffect(() => {
    setLoading(false);
    //If inspection was stored - we should display success reporting notification and clear stored inspection
    if (isInspectionStored) {
      if (isInspectionSent) {
        setShowSuccessReporting(true);
        dispatch(setInspectionSent(false));
      }
      dispatch(setInspectionQuestion(false));
    }
  }, [isInspectionStored, isInspectionSent, dispatch]);

  const redirectToNextStep = () => {
    const nextCategoryId = nextSubCategory
      ? currentCategoryId
      : nextCategory?.data?.id;
    const nextSubCat = nextSubCategory?.data?.id || null;
    const currentCategory = getCategory(
      surveysState.surveySequence,
      currentCategoryId
    );

    if (
      surveysState.currentSurvey?.survey.comments &&
      nextCategoryId !== currentCategoryId
    ) {
      //Survey has comments enabled
      props.history.push({
        pathname: reverse(routes.surveyCategoryComment, {
          id: surveyId,
          categoryId: currentCategoryId,
        }),
        state: {
          nextCategory: nextCategoryId,
          nextSubCategory: nextSubCat,
          comments: surveysState.currentSurvey?.survey.comments,
          categoryName: currentCategory?.data.title,
        },
      });
    } else {
      if (nextCategoryId) {
        goToCategory(surveyId, nextCategoryId, nextSubCat);
      } else {
        goToFinishSurveyPage(surveyId);
      }
    }
  };

  useEffect(() => {
    if (!respondentIdentifier) {
      throw new Error(`No stored respondent`);
    }

    if (surveysState.questionsList && currentQuestionId) {
      const totalQuestions = surveysState.questionsList.length;
      const currentQuestion = findIndex(
        surveysState?.questionsList,
        (d) => d === currentQuestionId
      );
      const customHeader =
        typeof surveysState.currentSurvey?.survey.questionHeader === "string"
          ? surveysState.currentSurvey?.survey.questionHeader
          : null;
      dispatch(
        setCurrentPageTitle(
          `${
            customHeader !== null
              ? customHeader
              : t("survey.question.count.prefix", {
                  lng: respondentIdentifier.lang,
                })
          } ${currentQuestion + 1} / ${totalQuestions}`
        )
      );
    }
    // eslint-disable-next-line
  }, [currentQuestionId, surveysState.questionsList]);

  useEffect(() => {
    if (surveysState.responses && currentQuestionId) {
      const currentQuestionComment = getAnswerComment(
        surveysState.responses.answers,
        currentQuestionId
      );
      if (currentQuestionComment) {
        setComment(currentQuestionComment);
      }
      setSelectedAnswer({
        ...selectedAnswer,
        answerComment: comment,
        id: getAnswer(surveysState.responses.answers, currentQuestionId),
      });
    }
    // eslint-disable-next-line
  }, [currentQuestionId, surveysState.responses]);

  const redirectToNextQuestion = () => {
    setComment("");
    setShowSuccessReporting(false);
    if (nextQuestion) {
      goToQuestion(
        surveyId,
        currentCategoryId,
        nextQuestion?.id,
        currentSubcategoryId
      );
    } else {
      redirectToNextStep();
    }
  };

  const setQuestions = (questions: Array<TApiQuestionEntity>) => {
    setQuestion(getQuestion(questions, currentQuestionId));
    setNextQuestion(getQuestion(questions, currentQuestionId, true));
  };

  // eslint-disable-next-line
  useEffect(() => {
    const currentCategory = getCategory(
      surveysState.surveySequence,
      currentCategoryId
    );
    const leaveCurrentCategory =
      (!currentSubcategoryId && !isEmpty(currentCategory?.subCategories)) ||
      !!nextSubCategory;
    if (currentCategory) {
      setNextCategory(
        getCategory(
          surveysState.surveySequence,
          currentCategoryId,
          !leaveCurrentCategory
        )
      );
    }
    if (!currentSubcategoryId) {
      if (currentCategory?.questions) {
        setQuestions(currentCategory.questions);
      }
      if (currentCategory?.subCategories) {
        setNextSubCategory(
          getCategory(currentCategory.subCategories, currentSubcategoryId, true)
        );
      }
    } else {
      if (currentCategory?.subCategories) {
        const subCategory = getCategory(
          currentCategory.subCategories,
          currentSubcategoryId
        );
        setNextSubCategory(
          getCategory(currentCategory.subCategories, currentSubcategoryId, true)
        );
        if (subCategory?.questions) {
          setQuestions(subCategory.questions);
        }
      }
    }
  });

  const submitAnswerCommentForm = (e: FormEvent, answerId: number) => {
    e.preventDefault();
    const answerData = find(
      question?.answers,
      (a) => a.id === answerId
    ) as TApiAnswerEntity;
    if (answerData) {
      return performRespond(answerData);
    }

    return false;
  };

  const performRespond = (answer: TApiAnswerEntity) => {
    setShowCommentBlock(false);
    const respondentIdentifier = loadStoredRespondentState();
    if (!respondentIdentifier) {
      throw new Error(`No stored respondent`);
    }
    const respondentId = respondentIdentifier.id;

    const questionId = question?.id as number;
    const answerId = answer.id;

    dispatch(
      surveyRespond({
        respondentId,
        questionId,
        answerId,
        comment: answer.allowComment ? comment : "",
      })
    ).then(() => {
      dispatch(getSurveyResponses(respondentId));
      setComment("");

      const questionSkipLogic = findSkipLogic(questionId, answerId);

      if (questionSkipLogic) {
        setShowSuccessReporting(false);

        switch (questionSkipLogic.type) {
          case SkipLogicType.JUMP_TO_QUESTION:
            const newQuestionId = questionSkipLogic.targetId as number;
            const parentInfo = getQuestionParentInfo(newQuestionId);
            if (parentInfo) {
              goToQuestion(
                surveyId,
                parentInfo.categoryId,
                newQuestionId,
                parentInfo.subcategoryId as number
              );
              return;
            }

            break;
          case SkipLogicType.JUMP_TO_CATEGORY:
            const newCategoryId = questionSkipLogic.targetId as number;
            const parentCategoryId = getCategoryParentId(newCategoryId);

            goToCategory(
              surveyId,
              parentCategoryId ? parentCategoryId : newCategoryId,
              parentCategoryId ? newCategoryId : 0
            );
            return;

          case SkipLogicType.END_SURVEY:
            goToFinishSurveyPage(surveyId);
            return;
        }
      }

      //Below is normal flow
      redirectToNextQuestion();
    });
  };

  if (!question || loading || app.isLoading(GetSurveyResponses)) {
    return <Loader visible={true} />;
  }

  const handleAnswerSubmit = (answerId: number) => {
    if (selectedAnswer && selectedAnswer.id !== answerId) {
      setComment("");
    }
    const answerData = find(
      question?.answers,
      (a) => a.id === answerId
    ) as TApiAnswerEntity;
    setSelectedAnswer(answerData);
    if (answerData.allowComment) {
      return setShowCommentBlock(true);
    }

    if (answerData.id && !app.isLoading(SurveyRespond)) {
      return performRespond(answerData as TApiAnswerEntity);
    }

    return false;
  };

  const handleReportCaseClick = () => {
    dispatch(setInspectionQuestion(true));
    props.history.push(reverse(routes.reportCase));
  };

  return (
    <>
      {question && (
        <>
          {surveysState.currentSurvey?.preview && (
            <>
              <AlertBlock hideCloseButton={true} type={"warning"}>
                <SurveyTranslation translate={"survey.preview"} />
              </AlertBlock>
            </>
          )}

          {showSuccessReporting && (
            <AlertBlock
              type={"success"}
              onClose={() => setShowSuccessReporting(false)}
            >
              {t("case.report.success.alert_long")}
            </AlertBlock>
          )}
          {app.hasError(SurveyRespond) && (
            <AlertBlock type={"error"}>
              {app.getError(SurveyRespond).message}
            </AlertBlock>
          )}
          <AnswerCommentModal
            showCommentBlock={showCommentBlock}
            setShowCommentBlock={setShowCommentBlock}
            submitAnswerCommentForm={submitAnswerCommentForm}
            question={question}
            comment={comment}
            setComment={setComment}
            selectedAnswer={selectedAnswer}
            answerCommentHeader={
              typeof surveysState.currentSurvey?.survey.answerCommentHeader ===
              "string"
                ? surveysState.currentSurvey?.survey.answerCommentHeader
                : null
            }
            answerCommentBody={
              typeof surveysState.currentSurvey?.survey.answerCommentBody ===
              "string"
                ? surveysState.currentSurvey?.survey.answerCommentBody
                : null
            }
            answerCommentLabel={
              typeof surveysState.currentSurvey?.survey.answerCommentLabel ===
              "string"
                ? surveysState.currentSurvey?.survey.answerCommentLabel
                : null
            }
            answerCommentSaveButton={
              typeof surveysState.currentSurvey?.survey
                .answerCommentSaveButton === "string"
                ? surveysState.currentSurvey?.survey.answerCommentSaveButton
                : null
            }
          />

          <Question
            handleReportCaseClick={
              surveysState.currentSurvey?.survey.surveyType ===
              SurveyInspectionType.INSPECTION
                ? handleReportCaseClick
                : undefined
            }
            question={question}
            selectedAnswer={selectedAnswer}
            handleAnswerSubmit={throttle(handleAnswerSubmit, 500)}
            comment={comment}
            redirectToNextQuestion={redirectToNextQuestion}
            questionRequired={
              typeof surveysState.currentSurvey?.survey.questionRequired ===
              "string"
                ? surveysState.currentSurvey?.survey.questionRequired
                : null
            }
            questionSkipButton={
              typeof surveysState.currentSurvey?.survey.questionSkipButton ===
              "string"
                ? surveysState.currentSurvey?.survey.questionSkipButton
                : null
            }
          />
        </>
      )}
    </>
  );
};

export default compose(
  withSurveyData,
  withRouter
)(SurveyQuestionPage) as React.FC;
